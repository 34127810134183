.categories_label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.categories_popup {
  position: absolute;
  margin-top: -16px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 2;
}

.category-list {
  padding: 0;
}

.categories-container {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.categories-list {
  width: 46%;
  display: flex;
  justify-content: space-between;
}

.category-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.categories_label {
  cursor: pointer;
  width: 140px;
  justify-content: center;
}

.categories_label-icon {
  width: 24px;
  height: 24px;
}
.categories_label-icon-rotate{  width: 24px;
  height: 24px;
  transform: rotate(180deg);
}
.categories_select {
  font-family: "Cormorant Garamond", serif;
}
.categories_label-active .categories_label-icon {
  opacity: 0.3;
}
.category-item {
  margin: 5px 5px;
  border: 0.1px solid rgb(212, 212, 212, 0.6);
  padding: 5px 5px;
  background-color: white;
  width: 160px;
  display: flex;
  justify-content: space-around;
  font-family: "Join", serif;
  font-style: italic;
  font-weight: 300;
  color: var(--price-color);
}
.category-item-active {
  border: 0px solid rgb(212, 212, 212, 0.6);
}
.category-item:hover {
  cursor: pointer;
  color: gray;
}

@media only screen and (max-width: 630px) {
  .category-item {
    width: 120px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 580px) {
  .categories_label {
    width: 120px;
  }

  .category-item {
    width: 100px;
  }
}

@media only screen and (max-width: 400px) {
  .category-item {
    padding: 3px 3px;
    font-size: 10px;
  }
  .categories_label-icon{
    width: 18px;
    height: 18px;
  }
  .categories_label-icon-rotate{
    width: 18px;
    height: 18px;
  }
}
@media only screen and (max-width: 380px) {
  .category-item {
    padding: 1.5px 1.5px;
    font-size: 9px;
    width: 80px;
  }
  .categories_label-icon{
    width: 15px;
    height: 15px;
  }
  .categories_label-icon-rotate{
    width: 15px;
    height: 15px;
  }
}

@media only screen and (max-width: 341px) {
  .category-item {
    padding: 2px 2px;
    font-size: 10px;
  }
}
