.order-item {
  margin: 20px 0;
  width: 250px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.item-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1px 1px 1px;

}

.item-title-container:hover {
  cursor: pointer;
}

.item-picture {
  width: 250px;
  height: 380px;
  object-fit: cover;
  margin-bottom: 10px;
  cursor: pointer;
}

.item-picture:hover {
  transform: scale(1.01);
}

.item-name {
  color: var(--price-color);
  font-size: 20px;
  font-weight: 800;
  width: 100%;
  height: 55px;
font-family: 'Jost', sans-serif;

 
}

.item-price {
  color: var(--price-color);
  font-size: 26px;
  text-align: right;
  font-weight: 600;
  padding: 0 0 0 1px;
  font-family: "Cormorant Garamond", serif;
}



@media only screen and (max-width: 1374px) {
  .order-item {
    width: 100%;
    max-width: 24vw;
  }

  .item-picture {
    width: 100%;
    max-width: 24vw;
    height: 34vw;
  }
}

@media only screen and (max-width: 1200px) {
  .order-item {
    width: 300px;
  }

  .item-picture {
    width: 300px;
    height: 360px;
  }
}

@media only screen and (max-width: 992px) {
  .order-item {
    width: 265px;
    max-width: 265px;
  }

  .item-picture {
    width: 265px;
    max-width: 265px;
    height: 320px;
  }
}

@media only screen and (max-width: 900px) {
  .order-item {
    width: 230px;
  }

  .item-picture {
    width: 230px;
    height: 280px;
  }

  .item-name {
    font-size: 20px;
  }
}

@media only screen and (max-width: 785px) {
  .order-item {
    width: 200px;
  }

  .item-picture {
    width: 200px;
    height: 260px;
  }

  .item-name {
    font-size: 20px;
  }
}

@media only screen and (max-width: 695px) {
  .order-item {
    width: 180px;
  }

  .item-picture {
    width: 180px;
    height: 240px;
  }

  .item-name {
    font-size: 18px;
  }

  .item-like {
    width: 30px;
    height: 30px;
  }

  .item-like-active {
    width: 30px;
    height: 30px;
  }
}

@media only screen and (max-width: 625px) {
  .order-item {
    width: 160px;
  }
  .item-name{
    font-size: 16px;
  }
  .item-price{
    font-size: 22px;
  }
  .item-picture {
    width: 160px;
    height: 240px;
  }
}

@media only screen and (max-width: 545px) {
  .order-item {
    max-width: 145px;
  }
  .item-name{
    font-size: 14px;
  }
  .item-picture {
    max-width: 145px;
    height: 220px;
  }
}

@media only screen and (max-width: 498px) {
  .order-item {
    width:140px;
    max-width: 140px;
  }

  .item-picture {
    max-width: 140px;
    width: 140px;
    height: 190px;
  }
  .item-price{
    font-size:20px ;
  }
  
}

@media only screen and (max-width: 485px) {
  .order-item {
    max-width: 120px;
    width: 100%;
  }

  .item-picture {
    max-width: 120px;
    height: 180px;  }
  
  .item-name{
    font-size: 12px;
    height: 45px;
  }

}




@media only screen and (max-width: 421px) {
 
  .order-item {
    max-width:115px;
  }

  .item-picture {
    width: 115px;
    height: 170px;
  }

  .item-name {
    font-size: 11px;
  }

  .item-price {
    font-size: 14px;
  }

}
@media only screen and (max-width: 400px) {
  .order-item {
    max-width: 110px;
    width: 100%;
  }

  .item-picture {
    max-width: 110px;
    height: 165px;  }
  
  .item-name{
    font-size: 10px;
    height: 40px;
  }
}
@media only screen and (max-width: 383px) {
  .order-item {
    max-width: 100px;
    width: 100%;
  }

  .item-picture {
    max-width: 100px;
    height: 150px;  }
  
  .item-name{
    font-size: 10px;
    height: 40px;
  }
}
@media only screen and (max-width: 345px) {
  .order-item {
    max-width: 90px;
    width: 100%;
  }

  .item-picture {
    max-width: 90px;
    height: 140px;  }
  
  .item-name{
    font-size: 10px;
    height: 40px;
  }
}