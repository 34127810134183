header {
  background-image: url("../../assets/20231026_120514.jpg");
  background-position-y: -130px;
  padding: 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;
}

.nav-item:nth-child(1) {
  display: none;
}

.header-content {
  width: 90%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  justify-content: space-between;
}

.logo {
  background-image: url("./logo.png");
  background-size: contain;
  width: 15em;
  height: 70px;
  background-repeat: no-repeat;
}

.logo:hover {
  opacity: 0.7;
}

.logo {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.logo-container {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

nav {
  padding-top: 20px;

  width: 60%;
  display: flex;
  justify-content: space-between;
}

.nav-item {
  color: #0d3033;
  font-size: 1.6em;
  font-weight: 800;
  font-family: "Cormorant Garamond", serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.nav-item:hover {
  opacity: 0.7;
}

li {
  list-style: none;
  cursor: pointer;
}

.number {
  display: flex;
}

.number-icon {
  width: 24px;
  height: 24px;
}

@media only screen and (max-width: 1180px) {
  .header-content {
    width: 90%;
  }
  header {
    background-position-y: -80px;
  }
}
@media only screen and (max-width: 954px) {
 
}

@media only screen and (max-width: 891px) {
  nav {
    width: 63%;
  }

  .nav-item {
    font-size: 1.6em;
  }
}
@media only screen and (max-width: 852px) {
  header{
    background-position-y:-50px ;
  }
}
@media only screen and (max-width: 768px) {
  nav {
    width: 60%;
    margin: 0;
  }

  .header-wrapper {
    width: 95%;
  }

  .nav-item {
    font-size: 20px;
  }

  .number {
    font-size: 12px;
  }

  .logo {
    margin-top: 5px;
    width: 120px;
    height: 50px;
  }
  header {
    background-position-y: -45px;
  }
}

@media only screen and (max-width: 668px) {
  nav {
    width: 80%;
    padding: 20px 20px 0 20px;
  }

  .header-wrapper {
    width: 90%;
  }
  .header-content {
    width: 95%;
  }
}
@media only screen and (max-width: 590px) {
  .header-content {
    width: 100%;
  }
  .logo-container{
    padding-left: 20px;
  }
}
@media only screen and (max-width: 552px) {
  header{
    background-position-y:-25px
  }
}
@media only screen and (max-width: 541px) {
  .nav-item{
    font-size: 18px;
  }
}
@media only screen and (max-width: 500px) {
  header{
    background-position-y: -25px;
  }
  .nav-item{
    font-size: 16px;
  }
}

@media only screen and (max-width: 480px) {
 

  nav {
    margin: 0;
  }

  .nav-item {
    padding: 20px 0;
    font-size: 20px;
    margin-left: -30px;
  }

  .nav-item:nth-child(1) {
    margin-top: 50px;
    display: block;
  }

  .menu-btn {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    width: 30px;
    height: 30px;
    position: relative;
    z-index: 4;
    margin-top: 15px;
    overflow: hidden;
  }
  .menu-btn:hover{
    cursor: pointer;
  }
  .menu-btn span {
    width: 30px;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #222222;
    transition: all 0.5s;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .menu-btn span:nth-of-type(2) {
    top: calc(50% - 5px);
  }

  .menu-btn span:nth-of-type(3) {
    top: calc(50% + 5px);
  }

  .menu-btn.active span:nth-of-type(1) {
    display: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .menu-btn.active span:nth-of-type(2) {
    top: 50%;
    transform: translate(-50%, 0%) rotate(45deg);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .menu-btn.active span:nth-of-type(3) {
    top: 50%;
    transform: translate(-50%, 0%) rotate(-45deg);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .header-nav {
    z-index: 3;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 15px;
    background: var(--header-color);
    transform: translateX(-100%);
    transition: transform 0.5s;
    flex-direction: column;
    justify-content: start;
    align-items: center;
  }

  .header-nav.active {
    transform: translateX(0);
  }

  .header-nav a {
    list-style-type: none;
  }
}
@media only screen and (max-width: 441px) {
  header{
    background-position-y:-5px;
  }
}
@media only screen and (max-width: 374px) {
  header{
    background-position-y: -5px;
  
  }
}
@media only screen and (max-width: 321px) {
  header{
    background-position-y: 0px;
  }
  .logo{
    margin-top: 0px;
  }
}
