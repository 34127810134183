.feedback {
  background-image: url("../../assets/20230215_114932.jpg");
  flex: 1 0 auto;
  width: 100%;
  height: auto;
  background-position-y: -300px;
  background-repeat: no-repeat;
  background-size: cover;
}
.feedback-wrapper {
  margin: 0 auto;
  width: 90%;
}
.feedback-social-container {
  margin-left: 100px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 20%;
}
.feedback-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
}
.feedback-title {
  padding: 40px 0 0 40px;
  font-family: "Jost", sans-serif;
  font-style: italic;
  font-size: 60px;
  color: #0d3033;
}
.icon-feedback-size {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: cover;
}
.icon-feedback-size:hover {
  opacity: 0.5;
  cursor: pointer;
}
.feedback-form-container {
  margin-top: 50px;
  margin-left: 40px;
  width: 45%;
  background-color: rgba(253, 253, 253, 0.6);
  padding: 20px 40px 40px 40px;
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.feedback-form-title {
  color: var(--price-color);
  font-weight: 600;
  font-family: "Cormorant Garamond", serif;
}

.feedback-form {
  display: flex;
  flex-direction: column;
}

.feedback-form-input {
  font-weight: 400;
  font-family: "Cormorant Garamond", serif;
  margin: 10px 0;
  padding: 10px;
  border: 2px solid var(--price-color);
  border-radius: 15px;
}

.bg {
  padding-top: 0px;
  height: 50px;
}

.feedback-form-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: var(--price-color);
  color: white;
  border-radius: 25px;
  font-size: 14px;
  width: 35%;
  font-family: "Jost", sans-serif;
  cursor: pointer;
  border: 1px solid var(--price-color);
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.feedback-form-button:hover {
  color: black;
  background-color: white;
  border: 1px solid black;
}

/* .image-container {
  width: 50%;
  height: 80vh;
  background-image: url("../../assets/20230721_181627.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -149px;
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
} */
@media only screen and (max-width: 1100px) {
  .feedback {
    background-position-y: -100px;
  }
}
@media only screen and (max-width: 992px) {
  .feedback-form-button {
    width: 45%;
  }
}
@media only screen and (max-width: 900px) {
  .feedback-social-container {
    width: 30%;
  }
}

@media only screen and (max-width: 829px) {
  .image-container {
    display: none;
  }

  .feedback-form-container {
    width: 60%;
  }
}
@media only screen and (max-width: 700px) {
  .feedback {
    background-position: center center;
  }
}
@media only screen and (max-width: 650px) {
  .feedback-form-button {
    width: 50%;
  }
}
@media only screen and (max-width: 614px) {
  .feedback-title {
    font-size: 55px;
  }
}
@media only screen and (max-width: 581px) {
  .feedback-form-button {
    width: 55%;
  }
}

@media only screen and (max-width: 571px) {
  .feedback-form-container {
    width: 70%;
  }

  .feedback-form-title {
    text-align: center;
  }

  .feedback-form-button {
    width: 60%;
  }
  .feedback-title {
    font-size: 50px;
  }
}
@media only screen and (max-width: 545px) {
  .feedback-social-container {
    width: 40%;
  }
}
@media only screen and (max-width: 522px) {
  .feedback-form-container {
    margin-left: 25px;
  }
  .feedback-title {
    font-size: 45px;
  }
}

@media only screen and (max-width: 472px) {
  .feedback-title {
    font-size: 40px;
  }
  .feedback-social-container {
    width: 45%;
    margin-left: 50px;
  }
}
@media only screen and (max-width: 452px) {
  .feedback-form-container {
    width: 90%;
  }
  .feedback-form-container {
    margin-left: 0px;
  }
  .feedback-form-input {
    font-size: 10px;
  }
}
@media only screen and (max-width: 425px) {
  .feedback-title {
    font-size: 36px;
  }
  .icon-feedback-size {
    width: 35px;
    height: 35px;
  }
}

@media only screen and (max-width: 420px) {
  .feedback-form-title {
    font-size: 14px;
  }

  .feedback-form-button {
    font-size: 12px;
  }
}
@media only screen and (max-width: 385px) {
  .feedback-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 345px) {
  .feedback-form-button {
    width: 70%;
  }
  .feedback-title {
    font-size: 28px;
  }
  .feedback-social-container {
    width: 50%;
    margin-left: 30px;
  }
  .feedback-form-container {
    padding: 20px 30px 20px 30px;
  }
}
