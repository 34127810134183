:root {
  --main-bg-color: #c19080;
  --main-br-color: #74635e;
  --header-color: #d4d4d4;
  --base-color: #ebebeb;
  --price-color: #0D3033;
}


.wrapper {

  max-width: 1920px;
  /* width: 98%; */
  margin: 0 auto;
  overflow: hidden;
  /* border: 2px solid var(--price-color); */
  /* border-radius: 20px; */
  min-height: 100svh;
  display: flex;
  flex-direction: column;
  height: auto;
}


.modal-open {

  overflow-y: hidden;
}

a {
  text-decoration: none;
}

.title-section--title {
  color: var(--main-bg-color);
}

.subtitle-sub {
  color: var(--main-br-color);
}

.title-section--subtitle {
  grid-area: subtitle;
}

@media only screen and (max-width: 1200px) {
  .wrapper {
    max-width: 1200px;
  }
}

@media only screen and (max-width: 992px) {
  .wrapper {
    max-width: 992px;
  }
}

@media only screen and (max-width: 768px) {
  .wrapper {
    max-width: 768px;
  }
}

@media only screen and (max-width: 480px) {
  .wrapper {
    max-width: 480px;
  }
}

@media only screen and (max-width: 320px) {
  .wrapper {
    max-width: 320px;
  }

}