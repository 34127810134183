.modal {
  width: 100%;

  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  padding-top: 0px;
  z-index: 4;
  /* overflow: hidden; */
}

.modal.active {
  opacity: 1;
  pointer-events: all;
}

.modal-container {
  border-radius: 12px;
  background-color: white;
  /* width: 70%; */
  margin: 0 auto;
  display: flex;
  transform: scale(1);
  transition: 0.4s all;



}

.slider-img {
  max-width: 450px;
  background-repeat: no-repeat;
  border-radius: 12px 0px 0 12px;
  background-size: contain;
  background-position: center;
  height: 600px;
}

.button {
  display: none;
  position: absolute;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.slider-container {
  max-width: 450px;
}

.slider-container:hover .button {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;

  border-radius: 100%;

  background-color: white;
}

.button:hover {
  cursor: pointer;
  opacity: 0.7;
}

.modal-description-container {
  padding: 20px 40px;
}

.modal-title {
  font-size: 1.6em;
  font-weight: 700;
  font-family: 'Jost', sans-serif;
  color: var(--price-color);
}

.modal-price {
  font-size: 1.4em;
  font-family: "Cormorant Garamond", serif;
  font-weight: 700;
  padding: 20px 0;
  color: var(--price-color);
}

.modal-description {
  font-family: "EB Garamond", serif;
  font-size: 1.4em;
  /* font-weight: 700; */
  text-align: justify;
  color: var(--price-color);
}

.modal-label {
  color: var(--price-color);
  padding: 20px 0 20px;
  font-family: "EB Garamond", serif;
  font-size: 1.2em;
}

.modal-button-order {
  margin: 0 auto;
  margin-top: 50px;
  width: 250px;
  height: 50px;
  background-color: var(--price-color);
  color: white;
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  border-radius: 25px;
  display: flex;
  font-family: "Jost", sans-serif;
  justify-content: center;
  border: 1px solid var(--price-color);
  align-items: center;
  font-size: 22px;
}

.modal-button-order:hover {
  cursor: pointer;
  color: black;
  background-color: white;

}

.close-modal-icon {
  width: 24px;
  height: 24px;
}

.modal-content-container {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.close-modal-container {
  width: 95%;
  display: flex;
  justify-content: flex-end;
}

.close-modal {
  display: flex;
  padding-top: 25px;
  background-color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
}

.close-modal:hover {
  cursor: pointer;
  opacity: 0.7;
}

.modal-container {
  width: 70%;
}

@media only screen and (max-width: 1406px) {
  .modal-container {
    width: 77%;
  }
}

@media only screen and (max-width: 1276px) {
  .modal-description-container {
    padding: 10px;
  }
}

@media only screen and (max-width: 1200px) {
  .modal-container {
    width: 80%;
  }

  .modal-description-container {
    padding: 10px 30px;
  }
}

@media only screen and (max-width: 1159px) {
  .modal-button-order {
    margin-top: 25px;
  }
}

@media only screen and (max-width: 1128px) {
  .modal-container {
    width: 90%;
  }
}

@media only screen and (max-width: 1004px) {
  .close-modal {
    padding-top: 6px;
  }
}

@media only screen and (max-width: 992px) {
  .modal-container {
    width: 80%;
  }

  .modal-description-container {
    padding: 8px;
  }

  .modal-label {
    font-size: 1em;
  }

  .close-modal {
    left: 86%;
    padding-top: 5px;
  }

  .modal-description {
    font-size: 1em;
  }

  .slider-img {
    max-width: 350px;
    height: 466px;
  }
}

.modal-content-container {
  width: 100%;
}

@media only screen and (max-width: 885px) {
  .modal-price {
    padding: 10px 0;
  }

  .modal-container {
    width: 85%;
  }
}

@media only screen and (max-width: 800px) {
  .modal-button-order {
    width: 180px;
    height: 35px;
    margin-top: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .close-modal {
    padding-top: 10px;
  }

  .modal-title {
    font-size: 1.2em;
  }

  .modal-price {
    padding: 10px 0;
    font-size: 1.2em;
  }

  .modal-description {
    font-size: 0.9em;
  }

  .modal-button-order {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 729px) {
  .modal-container {
    width: 95%;
  }
}

@media only screen and (max-width: 640px) {
  .window {
    height: 400px;
  }

  .slider-container {
    max-width: 300px;
  }

  .slider-img {
    height: 400px;
    max-width: 300px;
  }

  .right-button {
    margin-left: 260px;
  }

  .slider-container:hover .button {
    width: 40px;
    height: 40px;
  }
}

@media only screen and (max-width: 636px) {
  .modal-button-order {
    margin-top: 5px;
  }

  .close-modal {
    padding: 0px;
  }

  .modal-description-container {
    padding: 0 8px;
  }
}

@media only screen and (max-width: 600px) {
  .modal-button-order {
    width: 140px;
    height: 30px;
  }

  .close-modal-container {
    margin-top: 5px;
  }

  .close-modal {
    width: 25px;
    height: 25px;
  }

  .close-modal-icon {
    width: 18px;
    height: 18px;
  }

  .modal-price {
    padding: 5px 0;
  }
}

@media only screen and (max-width: 568px) {
  .modal {
    /* height: 100svh; */
    /* padding: 0px; */
    /* top: 0; */
    justify-content: flex-start;
    overflow-y: scroll;
  }

  .modal-description-container {
    padding: 0 15px;

  }

  .modal-label {
    font-size: 0.9em;
  }

  .modal-container {
    margin: auto;
    overflow-y: scroll;
    position: relative;
    flex-shrink: 0;

    /* margin-bottom: 40px; */
    flex-direction: column;
    align-items: flex-end;
    width: 90%;
    padding: 0px 0 20px;
  }

  .slider-container {
    width: 400px;
    max-width: 400px;
  }

  .close-modal-container {
    z-index: 3;
    width: 40px;
    position: absolute;
    /* top: 18%; */
    top: 3%;
    left: 87%;
  }

  .modal-description-container {
    height: 50%;
  }

  .modal-content-container {
    width: 100%;
  }

  .window {
    height: 340px;
  }

  .slider-img {
    max-width: 400px;
    height: 340px;
  }

  .right-button {
    margin-left: 62vw;
  }

  .modal-button-order {
    margin-top: 0px;
    font-size: 16px;
  }
}













/* 
@media only screen and (max-width: 442px) {
  .close-modal-container {
    left: 82%;
    margin-top: -70%;
  }

  .modal-container {
    width: 380px;
    overflow-x: hidden;
  }

  .right-button {
    margin-left: 75vw;
  }

  .window {
    width: 380px;
    max-width: 360px;
  }

  .slider-container {
    width: 380px;
    max-width: 380px;
  }
} */

@media only screen and (max-width: 427px) {


  .close-modal-container {
    left: 84%;
  }

  .right-button {
    margin-left: 76vw;
    width: 40px;
    height: 40px;
  }

  .slider-img {
    width: 380px;
  }
}

@media only screen and (max-width: 405px) {
  .right-button {
    margin: 0;
    left: 84%;
  }

  .button {
    background-color: transparent;
  }
}