footer {

  background-image: url('../../assets/20231026_120514.jpg');
  background-position-y: -130px;
  padding: 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  flex: 0 0 auto;
  /* margin: 0 auto;
  margin-top: auto; */
}

.footer-item {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.footer-content {
  margin: 0 auto;
  margin-top: auto;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.send-me {
  padding-top: 20px;
}

.social-container {
  padding-inline-start: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.footer-icon {
  padding: 0px 5px;
}

.footer-icon-pic {
  width: 24px;
  height: 24px;
}

.footer-icon:hover{
  opacity: 0.5;
}
.icon-telegram{
  background-image:url('../../assets/telegram.png');

}
.icon-footer-size{
  width:24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: cover;
}
.icon-instagram{
  background-image:url('../../assets/instagram.png');

}
.icon-whatsapp{
  background-image:url('../../assets/whatsapp.png');

}
@media only screen and (max-width: 1010px) {
 footer{
    background-position-y: -0px;
  }
}
@media only screen and (max-width: 480px) {
  footer {
    height: 55px;
  }
}