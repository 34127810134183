.session-container {
  padding: 40px 0px;
}

.session-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.skelet {
  width: 250px;
  height: 380px;
  --highlight-color: #f5f5f5;
  --animation-duration: 1.5s;
  --animation-direction: normal;
  --pseudo-element-display: block;
  background-color: var(--base-color);
  border-radius: 0.25rem;
  display: inline-flex;
  line-height: 1;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  overflow: hidden;
  z-index: 1;
}

.item-session-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 85px;
  grid-row-gap: 0px;
  justify-items: center;
}

.session-title {
  color: var(--price-color);
  font-size: 37px;
  font-weight: 700;
  font-family: "Jost", sans-serif;
}

.session-button {
  border-radius: 20px;
  border: 1px solid var(--price-color);
  font-family: "Jost", sans-serif;
  padding: 8px 25px;
  font-size: 22px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 800;
  color: var(--price-color);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

}

.session-button a {
  color: var(--price-color);
}

.session-button-icon {
  padding: 0 0 0 10px;
  width: 24px;
  height: 24px;
}

.session-button:hover a {
  color: white;
}

.session-button:hover {
  color: white;
}

.session-button:hover {
  background-color: var(--price-color);
}

.session-button:hover .session-button-icon {
  filter: invert(1);
}

@media only screen and (max-width: 1374px) {
  .skelet {
    width: 100%;
    max-width: 24vw;
    height: 34vw;
  }
}

@media only screen and (max-width: 1200px) {
  .skelet {
    width: 300px;
    height: 360px;
  }
}
/* 
@media only screen and (max-width: 1091px) {
  .item-session-container {
    grid-column-gap: 15px;
  }
}

@media only screen and (max-width: 1055px) {
  .item-session-container {
    grid-column-gap: 10px;
  }
} */

@media only screen and (max-width: 992px) {
  .skelet {
    width: 265px;
    max-width: 265px;
  }
}

@media only screen and (max-width: 900px) {
  .skelet {
    width: 230px;
    height: 280px;
  }
  .item-session-container{
    grid-column-gap: 25px;
  }
}
@media only screen and (max-width: 785px) {
  .skelet {
    width: 200px;
    height: 260px;
  }
}

@media only screen and (max-width: 768px) {
  .skelet {
    width: 200px;
    height: 260px;
  }
  .session-title {
    font-size: 28px;
  }
  .session-button {
    font-size: 16px;
  }
}

@media only screen and (max-width: 695px) {
  .skelet {
    width: 180px;
    height: 240px;
  }
}

@media only screen and (max-width: 625px) {
  .skelet {
    width: 160px;
    height: 240px;
  }
}

@media only screen and (max-width: 556px) {
  .session-container {
    padding: 20px 0;
  }
  .item-session-container {
    grid-column-gap: 7px;
  }
  .session-title {
    font-size: 24px;
  }
  .session-button {
    font-size: 14px;
    padding: 8px 20px;
  }
  .skelet {
    max-width: 145px;
    height: 220px;
  }
}

@media only screen and (max-width: 528px) {
  .session-title {
    font-size: 20px;
  }

  .session-button {
    font-size: 12px;
    padding: 4px 15px;
  }

  .skelet {
    width: 145px;
    height: 220px;
  }
}

@media only screen and (max-width: 498px) {
  .skelet {
    max-width: 140px;
    width: 140px;
    height: 190px;
  }

  .session-title {
    font-size: 20px;
  }

  .session-container {
    padding: 0px;
    margin: 20px 0 50px;
  }

  .session-button {
    font-size: 12px;
    padding: 4px 15px;
  }
  @media only screen and (max-width: 485px) {
    .skelet {
      max-width: 120px;
      height: 180px;
    }
  }
  @media only screen and (max-width: 440px) {
    .session-container {
      margin: 20px 0 20px;
    }
  }
  @media only screen and (max-width: 423px) {
    .skelet {
      width: 115px;
      height: 170px;
    }
  }

  @media only screen and (max-width: 407px) {
    .session-title {
      font-size: 16px;
    }

    .session-button {
      padding: 2px 10px;
      font-size: 10px;
    }

    .session-button-icon {
      width: 20px;
      height: 20px;
    }
    .skelet {
      max-width: 110px;
      height: 165px;
    }
  }

  @media only screen and (max-width: 390px) {
    .skelet {
      max-width: 110px;
      height: 165px;  
    }
  }
  @media only screen and (max-width: 383px) {
.skelet{
  max-width: 100px;
  height: 150px;
}
  }
  @media only screen and (max-width: 345px) {
    .skelet{
      max-width: 90px;
      height: 140px;
    }
  }
  @media only screen and (max-width: 330px) {
    .item-session-container {
      grid-column-gap: 5px;
    }

    .session-title {
      font-size: 14px;
    }
  }
}
