.order-section {
  width: 100%;
  margin: 0 auto;
}
.catalog-wrapper{
  margin: 0 auto;
    padding: 50px 0 50px 0;
    width: 80%;
    flex: 1 0 auto;
}
.order-section--title {
  display: flex;
  padding: 0 0 30px 0;
  justify-content: center;
  text-align: center;
  color: var(--price-color);
  font-family: "Cormorant Garamond", serif;
  font-size: 30px;
}

.content-order {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

}

@media only screen and (max-width: 998px) {
  .order-section--title{
    font-size: 26px;
  }
  .catalog-wrapper{
    width:93%;
  }
}
@media only screen and (max-width: 900px) {
  .catalog-wrapper{
    width:90%;
  }
}
@media only screen and (max-width: 845px) {
  .order-section--title{
    font-size: 24px;
  }
  .catalog-wrapper{
    width:93%;
  }
}
@media only screen and (max-width: 758px) {
  .order-section--title{
    font-size: 22px;
  }
}




@media only screen and (max-width: 480px) {
  .order-section--title {
    margin: 0 auto;
    text-align: center;
    font-size: 20px;
  }

  .content-order {
    width: 97%;
  }

  .category-container {
    width: 90%;

    padding: 0;
    margin: 0 auto;

  }

  .category-item {
    font-size: 8px;
  }
}

@media only screen and (max-width: 397px) {
  .order-section--title{
    font-size: 18px;
  }
  .order-section--title {
    width: 90%;
  }
}

@media only screen and (max-width: 383px) {
  .content-order {
    width: 100%;
  }
}

@media only screen and (max-width: 379px) {
  .content-order {
    width: 97%;
  }

  .order-section--title {
    width: 95%;
  }
}

@media only screen and (max-width: 320px) {
  .order-section--title {
    font-size: 16px;
  }


}