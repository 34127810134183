.helpful-title {
  text-align: center;
}

.helpful-h1 {
  text-align: center;
  color: var(--price-color);
  font-family: "Cormorant Garamond", serif;
}
.helpful-h3 {
  font-size: 23px;
  text-align: center;
  color: var(--price-color);
  font-family: "Cormorant Garamond", serif;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.helpful-info-container{
  margin: 30px 0;
}
@media only screen and (max-width: 1150px) {
  .helpful-h1{
    font-size: 26px;
  }
}
@media only screen and (max-width: 895px) {
  .helpful-h1{
    font-size: 24px;
  }
}
@media only screen and (max-width: 825px) {
  .helpful-h1{
    font-size: 22px;
  }
}
@media only screen and (max-width: 750px) {
  .helpful-h1{
    font-size: 20px;
  }
  .helpful-h3{
    font-size: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .helpful-info-container{
    margin: 15px 0;
  }
}

@media only screen and (max-width: 400px) {
  .helpful-h1 {
    font-size: 16px;
  }
  .helpful-h3{
    font-size: 12px;
  }
}
